import { FC, useMemo } from 'react';

import { Button, Dropdown, DropdownOption } from '@/shared/components';
import { ChevronRightIcon } from '@/shared/icons';
import { useQueryParams } from '@/shared/hooks';
import { QueryParams, ResponseMeta } from '@/shared/types';
import { jsonParse, jsonStringify } from '@/shared/utils';
import { OrderByEnum } from '@/shared/types/order';
import { DEFAULT_TABLE_ITEMS_SIZE, PAGINATION_KEY } from '@/shared/constants';

import styles from './pagination.module.scss';

type PaginationProps = {
  defaultOption?: DropdownOption;
  options?: DropdownOption[];
  meta?: ResponseMeta;
  disabled?: boolean;
};

const itemsPerPageOptions = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export const Pagination: FC<PaginationProps> = ({
  defaultOption = itemsPerPageOptions[0],
  options = itemsPerPageOptions,
  meta,
  disabled,
}) => {
  const { queryParams, setQueryParams } = useQueryParams();

  const paginationParams: QueryParams | null = jsonParse(queryParams.pagination);

  const itemsPerPage = paginationParams?.itemsPerPage as OrderByEnum;

  const page = Number(paginationParams?.['page']) || 0;

  const onNextPage = () => {
    setQueryParams({
      [PAGINATION_KEY]: jsonStringify({ itemsPerPage, page: page + 1 }),
    });
  };

  const onPrevPage = () => {
    setQueryParams({
      [PAGINATION_KEY]: jsonStringify({ itemsPerPage, page: page - 1 }),
    });
  };

  const setPageSize = (newPageSize: number) => {
    const pageSize = newPageSize || DEFAULT_TABLE_ITEMS_SIZE;

    setQueryParams({
      [PAGINATION_KEY]: jsonStringify({ itemsPerPage: pageSize, page: 0 }),
    });
  };

  const shownItemsFrom = useMemo(() => {
    if (!meta) return;

    const metaPage = meta.page + 1;

    if (metaPage > 1) {
      return (metaPage - 1) * meta.itemsPerPage;
    }

    if (metaPage === 1 && meta.total === 0) {
      return 0;
    }

    return metaPage;
  }, [meta]);

  if (!meta) return null;

  const metaPage = meta.page + 1;

  const shownItemsTo = metaPage === meta.totalPages ? meta.total : metaPage * meta.itemsPerPage;
  const itemsShown = `${shownItemsFrom}-${shownItemsTo}`;

  const disablePrevButton = page === 0;
  const disableNextButton = !meta.totalPages || page === meta.totalPages - 1;

  return (
    <div className={styles['container']}>
      <div className={styles['pages-info']}>
        <div className={styles['dropdown-container']}>
          <p className={styles['rows-per-page']}>Rows per page:</p>

          <Dropdown
            disabled={disabled}
            defaultOption={defaultOption}
            options={options}
            selectedOption={
              itemsPerPage ? { label: itemsPerPage.toString(), value: itemsPerPage.toString() } : options[0]
            }
            onSelectOption={(option) => setPageSize(Number(option.value))}
          />
        </div>

        <p className={styles['count']}>
          {itemsShown} of {meta.total}
        </p>
      </div>

      <div className={styles['arrow-actions']}>
        <Button
          className={styles['back-action']}
          variant="icon"
          onClick={onPrevPage}
          disabled={disabled || disablePrevButton}
        >
          <ChevronRightIcon />
        </Button>

        <Button
          className={styles['next-action']}
          variant="icon"
          onClick={onNextPage}
          disabled={disabled || disableNextButton}
        >
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};
