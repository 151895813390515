import { FC } from 'react';

import styles from './rating.module.scss';
import { FilledStarIcon, HalfFilledStarIcon, StrokeStarIcon } from '@/shared/icons';

type RatingProps = {
  rating: number;
};

export const Rating: FC<RatingProps> = ({ rating }) => {
  const stars = [];
  const integerPart = Math.floor(rating);
  const decimalPart = rating - integerPart;
  const maxRating = 5;

  // Fill stars based on rating's integer part
  for (let i = 1; i <= maxRating; i++) {
    if (i <= integerPart) {
      // Filled star
      stars.push(FilledStarIcon);
    } else if (i === integerPart + 1 && decimalPart !== 0) {
      // Half-filled star
      stars.push(HalfFilledStarIcon);
    } else {
      // Non-filled star
      stars.push(StrokeStarIcon);
    }
  }

  const roundedRating = rating.toFixed(1);

  return (
    <div className={styles['container']}>
      <div className={styles['icons-container']}>
        {stars.map((Icon, i) => (
          <Icon key={i} />
        ))}
      </div>

      <p className={styles['rating']}>{roundedRating}</p>
    </div>
  );
};
