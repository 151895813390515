import { FC, useMemo, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

import { Button, Input } from '@/shared/components';
import { CrossIcon, SearchIcon } from '@/shared/icons';

import styles from './search-filter.module.scss';

const SEARCH_DEBOUNCE_TIME = 300;

type SearchFilterProps = {
  searchedKeyword?: string;
  onSubmit: (keyword?: string) => void;
  onClear: () => void;
};

export const SearchFilter: FC<SearchFilterProps> = ({ searchedKeyword, onSubmit, onClear }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = useMemo(() => debounce(onSubmit, SEARCH_DEBOUNCE_TIME), [onSubmit]);

  const hasValue = !!inputRef.current?.value;

  const handleClear = () => {
    if (!inputRef.current) return;

    inputRef.current.value = '';
    onClear();
  };

  useEffect(() => {
    if (!searchedKeyword || !inputRef.current) return;

    inputRef.current.value = searchedKeyword;
  }, [searchedKeyword]);

  return (
    <div className={styles['container']}>
      <Input
        ref={inputRef}
        className={styles['input']}
        placeholder="Search"
        startIcon={<SearchIcon />}
        endIcon={
          hasValue && (
            <Button variant="icon" onClick={handleClear}>
              <CrossIcon />
            </Button>
          )
        }
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};
