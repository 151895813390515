import { API_ROUTE_PREFIX } from '@/shared/constants';

const AUTH_SCOPE_ROUTE = 'auth';

export const LOGIN_ENDPOINT = `${API_ROUTE_PREFIX}/${AUTH_SCOPE_ROUTE}/signin`;
export const LOGOUT_ENDPOINT = `${API_ROUTE_PREFIX}/${AUTH_SCOPE_ROUTE}/logout`;

const RECOVER_SCOPE_ROUTE = 'recover';
const RECOVER_PASSWORD_SCOPE_ROUTE = 'password';

export const RESET_PASSOWRD_ENDPOINT = `${API_ROUTE_PREFIX}/${RECOVER_SCOPE_ROUTE}/${RECOVER_PASSWORD_SCOPE_ROUTE}/request-reset`;
export const SET_PASSOWRD_ENDPOINT = `${API_ROUTE_PREFIX}/${RECOVER_SCOPE_ROUTE}/${RECOVER_PASSWORD_SCOPE_ROUTE}/set-new`;
