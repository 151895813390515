import { LoginFailureResponseDto } from '@/features/auth/dto';
import isPlainObject from 'lodash/isPlainObject';

export const isAuthErrors = (payload: unknown): payload is LoginFailureResponseDto => {
  if (isPlainObject(payload)) {
    const payloadObject = payload as Record<string, unknown>;

    return 'data' in payloadObject && 'errors' in (payloadObject.data as LoginFailureResponseDto['data']);
  }

  return false;
};
