import React, { FC, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { SuspenseError } from '@/shared/components';
import { isProd } from '@/shared/constants';

type SentryProviderProps = {
  children: ReactNode;
};

export const SentryProvider: FC<SentryProviderProps> = ({ children }) => {
  if (!isProd) return <>{children}</>;

  return <Sentry.ErrorBoundary fallback={<SuspenseError cover />}>{children}</Sentry.ErrorBoundary>;
};
