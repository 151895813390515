import { FC, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import Dialog from 'react-modal';

import { ROOT_ELEMENT_ID } from '@/shared/constants/modal';

import style from './base-modal.module.scss';

export interface BaseModalProps extends Dialog.Props {
  isOpen: boolean;
  ariaLabel?: string;
  className?: string;
  hideModal?: () => void;
  role?: string;
  children?: ReactNode;
  lockPageScroll?: boolean;
}

export const BaseModal: FC<BaseModalProps> = ({
  children,
  isOpen,
  ariaLabel,
  className,
  overlayClassName,
  hideModal,
  role = 'dialog',
  lockPageScroll = true,
  ...other
}) => {
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const element = document.getElementById(ROOT_ELEMENT_ID);

    if (!element) return;

    setRootElement(element);
  }, []);

  useEffect(() => {
    if (isOpen && lockPageScroll) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, lockPageScroll]);

  if (!rootElement) return null;

  return (
    <Dialog
      role={role}
      isOpen={isOpen}
      className={clsx(className, style['content'])}
      overlayClassName={clsx(overlayClassName, style['overlay'])}
      onRequestClose={hideModal}
      appElement={rootElement}
      contentLabel={ariaLabel}
      {...other}
    >
      {children}
    </Dialog>
  );
};

export default BaseModal;
