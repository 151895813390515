import { FC, ReactNode } from 'react';

import styles from './page-wrapper.module.scss';

type PageWrapperProps = {
  children: ReactNode;
};

export const PageWrapper: FC<PageWrapperProps> = ({ children }) => (
  <section className={styles['container']}>{children}</section>
);
