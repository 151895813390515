import { FC } from 'react';
import clsx from 'clsx';

import { ErrorText } from '@/shared/components/errors';

import styles from './error-list.module.scss';

type ErrorListProps = {
  errors: (string | undefined)[];
  containerClassName?: string;
};

export const ErrorList: FC<ErrorListProps> = ({ errors, containerClassName }) => {
  return (
    <ul className={clsx(containerClassName, styles['error-list'])}>
      {errors.map((e, i) => (
        <li key={i} className={styles['error']}>
          <ErrorText error={e} />
        </li>
      ))}
    </ul>
  );
};
