import { api } from '@/setup/redux/query';

import { ResponseCategoriesDto } from '@/features/categories/dto';

import { CATEGORIES_ENDPOINT } from './endpoints';

export const categoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<ResponseCategoriesDto, void>({
      query: () => ({
        url: CATEGORIES_ENDPOINT,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesApi;
