import { QueryParams } from '@/shared/types';
import { SORT_TABLE_KEY } from '@/shared/constants';
import { ColumnOrderBy, OrderByEnum } from '@/shared/types/order';
import { jsonStringify } from '@/shared/utils/json-helpers';

export const isAscending = (direction: OrderByEnum): direction is OrderByEnum.ASC => direction === OrderByEnum.ASC;
export const isDescending = (direction: OrderByEnum): direction is OrderByEnum.DESC => direction === OrderByEnum.DESC;
export const isNone = (direction: OrderByEnum): direction is OrderByEnum.NONE => direction === OrderByEnum.NONE;

const makeOrderingData = (sortName: string, order: OrderByEnum): ColumnOrderBy => ({
  column: sortName,
  order,
});

const sortByDescendingFirst = (
  sortName: string,
  setQueryParams: (query: QueryParams) => void,
  prevSortDirection: OrderByEnum,
) => {
  if (isNone(prevSortDirection)) {
    setQueryParams({ [SORT_TABLE_KEY]: jsonStringify(makeOrderingData(sortName, OrderByEnum.DESC)) });
  }

  if (isDescending(prevSortDirection)) {
    setQueryParams({ [SORT_TABLE_KEY]: jsonStringify(makeOrderingData(sortName, OrderByEnum.ASC)) });
  }

  if (isAscending(prevSortDirection)) {
    setQueryParams({ [SORT_TABLE_KEY]: jsonStringify({}) });
  }
};

const sortByAscendingFirst = (
  sortName: string,
  setQueryParams: (query: QueryParams) => void,
  prevSortDirection: OrderByEnum,
) => {
  if (isNone(prevSortDirection)) {
    setQueryParams({ [SORT_TABLE_KEY]: jsonStringify(makeOrderingData(sortName, OrderByEnum.ASC)) });
  }

  if (isAscending(prevSortDirection)) {
    setQueryParams({ [SORT_TABLE_KEY]: jsonStringify(makeOrderingData(sortName, OrderByEnum.DESC)) });
  }

  if (isDescending(prevSortDirection)) {
    setQueryParams({ [SORT_TABLE_KEY]: jsonStringify({}) });
  }
};

export const handleSort = (
  sortName: string,
  isDescendingFirst: boolean,
  setQueryParams: (query: QueryParams) => void,
  prevSortDirection: OrderByEnum,
) => {
  if (isDescendingFirst) {
    sortByDescendingFirst(sortName, setQueryParams, prevSortDirection);
    return;
  }

  sortByAscendingFirst(sortName, setQueryParams, prevSortDirection);
};
