import { FC } from 'react';

import { useAuth } from '@/features/auth';

import { AuthorizedLayout } from './authorized-layout';
import { UnauthorizedLayout } from './unauthorized-layout';

export const LayoutContainer: FC = () => {
  const { isAuthorized } = useAuth();

  if (isAuthorized) {
    return <AuthorizedLayout />;
  }

  return <UnauthorizedLayout />;
};
