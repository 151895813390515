import { FC } from 'react';
import clsx from 'clsx';

import styles from './category-and-subcategory.module.scss';

type CategoryAndSubcategoryProps = {
  category: string;
  subcategory: string;
  ellipsis?: boolean;
};

export const CategoryAndSubcategory: FC<CategoryAndSubcategoryProps> = ({ category, subcategory, ellipsis }) => {
  const title = `${category} • ${subcategory}`;

  return (
    <div className={styles['container']}>
      <p className={clsx(styles['text'], ellipsis && styles['ellipsis'])} title={title}>
        {title}
      </p>
    </div>
  );
};
