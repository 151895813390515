import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input, PasswordInput, Button } from '@/shared/components';
import { APP_LINKS } from '@/configs';
import { LoginFormFieldErrors } from '@/features/auth/types/errors';
import { ErrorList } from '@/shared/components/errors';
import { resetErrors } from '@/features/auth/slices';
import { useAppDispatch } from '@/shared/hooks';

import { FormWrapper } from '../form-wrapper';
import { loginValidationSchema } from './login-validation-schema';

import styles from './login-form.module.scss';

export type FormValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  disableAction: boolean;
  onSubmit: (v: FormValues) => void;
  errors: null | LoginFormFieldErrors;
};

const formFieldsMap = {
  email: 'email',
  password: 'password',
} as const;

export const LoginForm: FC<LoginFormProps> = ({ onSubmit, disableAction, errors: serverErrors }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (serverErrors?.fieldErrors) {
      serverErrors?.fieldErrors[formFieldsMap.email]?.forEach((message) => {
        setError(formFieldsMap.email, { message });
      });

      serverErrors?.fieldErrors[formFieldsMap.password]?.forEach((message) => {
        setError(formFieldsMap.password, { message });
      });
    }
  }, [serverErrors, setError]);

  const resetErrorsOnChange = () => {
    if (commonErrors?.length) {
      dispatch(resetErrors());
    }
  };

  const emailErrors = [errors[formFieldsMap.email]?.message];
  const passwordErrors = [errors[formFieldsMap.password]?.message];
  const commonErrors = serverErrors?.nonFieldErrors;

  return (
    <FormWrapper>
      <form className={styles['form']} noValidate onSubmit={handleSubmit(onSubmit)}>
        <h1 className={styles['title']}>Login</h1>

        <Controller
          name={formFieldsMap.email}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              containerClassName={styles['email-input']}
              type="email"
              placeholder="Email"
              errors={emailErrors}
              onChange={(e) => {
                field.onChange(e);
                resetErrorsOnChange();
              }}
            />
          )}
        />

        <Controller
          name={formFieldsMap.password}
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              containerClassName={styles['password-input']}
              placeholder="Password"
              errors={passwordErrors}
              onChange={(e) => {
                field.onChange(e);
                resetErrorsOnChange();
              }}
            />
          )}
        />

        <Link className={styles['link']} to={APP_LINKS.RESET_PASSWORD}>
          Forgot password?
        </Link>

        <Button className={styles['button']} fullWidth type="submit" disabled={disableAction}>
          Log in
        </Button>

        {!!commonErrors?.length && (
          <ErrorList containerClassName={styles['common-errors-container']} errors={commonErrors} />
        )}
      </form>
    </FormWrapper>
  );
};
