import { FC } from 'react';
import { TableBodyRow } from '@/shared/components';
import clsx from 'clsx';

import styles from './table-message.module.scss';

type Variant = 'info' | 'error';

type TableMessageProps = {
  title: string;
  description?: string;
  variant?: Variant;
};

export const TableMessage: FC<TableMessageProps> = ({ title, description, variant = 'info' }) => {
  return (
    <TableBodyRow bodyRowClassName={styles['row']}>
      <div className={styles['wrapper']}>
        <h2 className={clsx(styles['title'], styles[variant])}>{title}</h2>
        {description && <p className={clsx(styles['description'], styles[variant])}>{description}</p>}
      </div>
    </TableBodyRow>
  );
};
