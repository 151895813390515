import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ArrowDownDarkIcon } from '@/shared/icons';

import styles from './go-back.module.scss';

type GoBackProps = {
  label: string;
  link: string;
};

export const GoBack: FC<GoBackProps> = ({ label, link }) => {
  return (
    <div className={styles['navigation']}>
      <Link to={link} className={styles['navigation-link']}>
        <ArrowDownDarkIcon className={styles['navigation-link-icon']} />
        <span>{label}</span>
      </Link>
    </div>
  );
};
