import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './table-head-row.module.scss';

type TableHeadRowProps = {
  headRowClassName?: string;
  children: ReactNode;
};

export const TableHeadRow: FC<TableHeadRowProps> = ({ headRowClassName, children }) => {
  return (
    <div role="row" className={clsx(headRowClassName, styles['table-head'])}>
      {children}
    </div>
  );
};
