import { useEffect, useState } from 'react';
import { isResetPasswordEmailSentError } from '@/features/auth/utils';
import { useResetPasswordMutation } from '@/features/auth/services';
import { RESET_PASSWORD_EMAIL_SHARED_CACHE_KEY } from '@/features/auth/constants';
import { useUnmount } from 'react-use';

enum FormStateStatusEnum {
  EMAIL_FORM,
  SUCCESS,
  UNEXPECTED_ERROR,
}

export const useFormStateStatus = (userEnteredEmail: string | null) => {
  const [status, setStatus] = useState(FormStateStatusEnum.EMAIL_FORM);
  const [, { reset, isSuccess, isError, error }] = useResetPasswordMutation({
    fixedCacheKey: RESET_PASSWORD_EMAIL_SHARED_CACHE_KEY,
  });

  const isEmailSent = isSuccess || isResetPasswordEmailSentError(error);

  useUnmount(reset);

  useEffect(() => {
    if (isEmailSent && userEnteredEmail) {
      setStatus(FormStateStatusEnum.SUCCESS);
      return;
    }

    if (isError) {
      setStatus(FormStateStatusEnum.UNEXPECTED_ERROR);
      return;
    }
  }, [isEmailSent, isError, userEnteredEmail]);

  return status;
};
