import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

import { ArrowDownIcon } from '@/shared/icons';

import styles from './dropdown-trigger.module.scss';

type DropdownTriggerProps = {
  label: string;
  children: string;
  endIcon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const DropdownTrigger = forwardRef<HTMLButtonElement, DropdownTriggerProps>(
  ({ label, children, endIcon = <ArrowDownIcon />, ...restProps }, ref) => {
    return (
      <button title={children} className={styles['trigger']} ref={ref} {...restProps}>
        <span className={styles['label']}>{label}</span>
        <span className={styles['children']}>{children}</span>
        {endIcon}
      </button>
    );
  },
);
