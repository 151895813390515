import { isFetchBaseQueryErrorType } from '@/shared/utils';
import { ResetPasswordFailureResponseDto, SetPasswordFailureResponseDto } from '@/features/auth/dto';

export const isResetPasswordEmailSentError = (error: unknown) => {
  if (isFetchBaseQueryErrorType(error)) {
    const responseData = error?.data as ResetPasswordFailureResponseDto;

    return responseData?.data?.status === 'VERIFICATION_IS_ALREADY_SENT_EXCEPTION';
  }

  return false;
};

export const isSetPasswordError = (error: unknown): error is { data: SetPasswordFailureResponseDto } => {
  if (isFetchBaseQueryErrorType(error)) {
    const responseData = error?.data as SetPasswordFailureResponseDto;

    return !!responseData?.data?.errors?.fieldErrors;
  }

  return false;
};
