import { forwardRef, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@/shared/icons';

import { Input, InputProps } from '../input/input';

type PasswordInputProps = InputProps;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const onShow = () => {
    setShowPassword((prev) => !prev);
  };

  const inputIcon = showPassword ? (
    <EyeSlashIcon width="1.5rem" height="1.5rem" />
  ) : (
    <EyeIcon width="1.5rem" height="1.5rem" />
  );

  return (
    <>
      <Input ref={ref} {...props} type={showPassword ? 'text' : 'password'} endIcon={inputIcon} onIconClick={onShow} />
    </>
  );
});
