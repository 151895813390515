import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './title-with-content.module.scss';

type TitleWithContentProps = {
  variant?: 'row' | 'columns';
  title: string;
  children: ReactNode;
  textClassName?: string;
};

export const TitleWithContent: FC<TitleWithContentProps> = ({ title, children, variant = 'column', textClassName }) => {
  return (
    <div className={clsx(styles['container'], styles[variant])}>
      <h6 className={styles['title']}>{title}</h6>
      <div className={clsx(textClassName, styles['text'])}>{children}</div>
    </div>
  );
};
