import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useUnmount } from 'react-use';

import { useModal } from '@/shared/hooks';

import { AuthorizedHeader } from './authorized-header';

import styles from './authorized-layout.module.scss';

export const AuthorizedLayout: FC = () => {
  const { hideModal } = useModal();
  useUnmount(hideModal);

  return (
    <div className={styles['container']}>
      <AuthorizedHeader />
      <Outlet />
    </div>
  );
};
