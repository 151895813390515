import React from 'react';
import * as Sentry from '@sentry/react';
import ReactModal from 'react-modal';

import { AppProviders } from 'providers';
import { isProd, SENTRY_DSN, SENTRY_ENVIRONMENT } from '@/shared/constants';

import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import './app.scss';
import { AppRoutes } from '@/routes/_routes';
import { ROOT_ELEMENT_ID } from '@/shared/constants/modal';

if (isProd) {
  Sentry.init({ dsn: SENTRY_DSN, environment: SENTRY_ENVIRONMENT });
}

ReactModal.setAppElement(`#${ROOT_ELEMENT_ID}`);

function App() {
  return (
    <div className="App">
      <AppProviders>
        <AppRoutes />
      </AppProviders>
    </div>
  );
}

export default App;
