import { concatLinkWithQuery } from '@/shared/utils';
import {
  customersDefaultQueryParams,
  dashboardPageDefaultQueryParams,
  handsDefaultQueryParams,
  jobsHistoryDefaultQueryParams,
  receiveCustomerJobsHistoryDefaultQueryParams,
  receiveHandJobsHistoryDefaultQueryParams,
} from './default-query';

export const APP_ROUTES = {
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  RESET_PASSWORD__SET_PASSWORD: 'set-password',
  DASHBOARD: 'dashboard',
  JOB_HISTORY: 'job-history',
  JOB_HISTORY__JOB_DETAILS: ':jobId',
  USER_MANAGEMENT: 'user-management',
  USER_MANAGEMENT__HANDS: 'hands',
  USER_MANAGEMENT__HANDS__CONTROL: ':handId',
  USER_MANAGEMENT__CUSTOMERS: 'customers',
  USER_MANAGEMENT__CUSTOMERS__CONTROL: ':customerId',
} as const;

export const APP_LINKS = {
  LOGIN: `/${APP_ROUTES.LOGIN}`,
  RESET_PASSWORD: `/${APP_ROUTES.RESET_PASSWORD}`,
  RESET_PASSWORD__SET_PASSWORD: `/${APP_ROUTES.RESET_PASSWORD}/${APP_ROUTES.RESET_PASSWORD__SET_PASSWORD}`,
  DASHBOARD: concatLinkWithQuery(`/${APP_ROUTES.DASHBOARD}`, dashboardPageDefaultQueryParams),
  JOB_HISTORY: concatLinkWithQuery(`/${APP_ROUTES.JOB_HISTORY}`, jobsHistoryDefaultQueryParams),
  jobHistoryDetails: (jobId: string) => `/${APP_ROUTES.JOB_HISTORY}/${jobId}`,
  USER_MANAGEMENT: `/${APP_ROUTES.USER_MANAGEMENT}`,
  USER_MANAGEMENT__HANDS: concatLinkWithQuery(
    `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.USER_MANAGEMENT__HANDS}`,
    handsDefaultQueryParams,
  ),
  userManagementHandsControl: (handId: string) =>
    concatLinkWithQuery(
      `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.USER_MANAGEMENT__HANDS}/${handId}`,
      receiveHandJobsHistoryDefaultQueryParams(handId),
    ),
  USER_MANAGEMENT__CUSTOMERS: concatLinkWithQuery(
    `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.USER_MANAGEMENT__CUSTOMERS}`,
    customersDefaultQueryParams,
  ),
  userManagementCustomersControl: (customerId: string) =>
    concatLinkWithQuery(
      `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.USER_MANAGEMENT__CUSTOMERS}/${customerId}`,
      receiveCustomerJobsHistoryDefaultQueryParams(customerId),
    ),
} as const;
