import { FC, ReactNode } from 'react';

import styles from './filters-page-header.module.scss';

type FiltersPageHeaderProps = {
  children: ReactNode;
};

export const FiltersPageHeader: FC<FiltersPageHeaderProps> = ({ children }) => (
  <div className={styles['header']}>{children}</div>
);
