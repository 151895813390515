import { FC, HTMLAttributes } from 'react';

import styles from './error-text.module.scss';

type ErrorTextProps = { error?: string } & HTMLAttributes<HTMLParagraphElement>;

export const ErrorText: FC<ErrorTextProps> = ({ error, ...restProps }) => {
  if (!error) return null;

  return (
    <p {...restProps} className={styles['error']} role="alert" aria-label={error}>
      {error}
    </p>
  );
};
