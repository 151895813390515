import { FC } from 'react';
import clsx from 'clsx';
import { NavLink as ReactRouterNavLink, NavLinkProps as ReactRouterNavLinkProps } from 'react-router-dom';

type NavLinkProps = {
  activeClassName: string;
} & ReactRouterNavLinkProps;

export const NavLink: FC<NavLinkProps> = ({ activeClassName, className: classNameProp, ...restProps }) => {
  return (
    <ReactRouterNavLink {...restProps} className={({ isActive }) => clsx(classNameProp, isActive && activeClassName)} />
  );
};
