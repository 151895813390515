import { FC, useState } from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';

import { useClickOutside } from '@/shared/hooks';
import { ArrowDropDownIcon } from '@/shared/icons';

import style from './dropdown.module.scss';

export type DropdownOption = {
  label: string;
  value: string;
  [key: string]: unknown;
};

export type DropdownProps = {
  optionLoading?: boolean;
  options: DropdownOption[];
  onSelectOption: (option: DropdownOption) => void;
  defaultOption?: DropdownOption;
  // if you use the `selectedOption` prop, you should use external state for saving selected option
  // the `null` type should be passed when an option is not selected
  selectedOption?: DropdownOption | null;
  disabled?: boolean;
  triggerLabelClassName?: string;
  triggerIconClassName?: string;
  triggerClassName?: string;
  triggerAriaLabel?: string;
  menuClassName?: string;
  menuAriaLabel?: string;
};

export const Dropdown: FC<DropdownProps> = ({
  defaultOption,
  optionLoading,
  options,
  selectedOption,
  onSelectOption,
  disabled,
  triggerLabelClassName,
  triggerIconClassName,
  triggerClassName,
  triggerAriaLabel,
  menuClassName,
  menuAriaLabel,
}) => {
  const [internalSelectedOption, setInternalSelectedOption] = useState<DropdownOption | null>(defaultOption || null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
    placement: 'bottom',
  });

  useClickOutside([referenceElement, popperElement], () => setIsOpenDropdown(false));

  const isExternalSelectedOptionState = selectedOption || selectedOption === null;

  const dropdownLabel = isExternalSelectedOptionState ? selectedOption?.label : internalSelectedOption?.label;

  return (
    <>
      <button
        aria-label={triggerAriaLabel}
        disabled={disabled}
        onClick={() => {
          setIsOpenDropdown((prev) => !prev);
        }}
        className={clsx(triggerClassName, style['dropdown-indicator'])}
        ref={setReferenceElement}
      >
        <p className={clsx(triggerLabelClassName, style['dropdown-indicator-label'])}>{dropdownLabel}</p>

        <ArrowDropDownIcon
          className={clsx(triggerIconClassName, style['dropdown-indicator-icon'], isOpenDropdown && style['open'])}
        />
      </button>

      {isOpenDropdown && (
        <div
          aria-label={menuAriaLabel}
          className={clsx(menuClassName, style['kebab-menu-dropdown'])}
          ref={setPopperElement}
          style={popper.styles['popper']}
        >
          {optionLoading ? (
            <div className={style['kebab-menu-dropdown-item-loading']}>Loading...</div>
          ) : (
            options.map((option) => {
              const isSelected = selectedOption?.value === option.value;

              return (
                <button
                  key={option.value}
                  className={clsx(
                    style['kebab-menu-dropdown-item'],
                    isSelected && style['kebab-menu-dropdown-item-selected'],
                  )}
                  onClick={() => {
                    if (!isExternalSelectedOptionState) {
                      setInternalSelectedOption(option);
                    }

                    setIsOpenDropdown(false);
                    onSelectOption(option);
                  }}
                  aria-label={`${option.label} option`}
                >
                  {option.label}
                </button>
              );
            })
          )}
        </div>
      )}
    </>
  );
};
