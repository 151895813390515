import { useEffect } from 'react';

type ClickElement = HTMLElement | null;

export const useClickOutside = (
  elems: ClickElement | ClickElement[],
  handler: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    const arrayOfElems = [elems].flat().filter(Boolean) as HTMLElement[];

    const listener: typeof handler = (event) => {
      if (!event.target) {
        return;
      }

      if (arrayOfElems.some((elem) => elem.contains(event.target as Node))) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [elems, handler]);
};
