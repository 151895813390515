import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

import { RootState } from '@/setup';
import { AUTH_SLICE_NAME } from '@/features/auth/slices/constants';
import { LoginResponseDto } from '@/features/auth/dto/login-dto';
import { authApi } from '@/features/auth/services/auth';
import { isAuthErrors } from '@/features/auth/slices/utils';
import { LoginFormFieldErrors } from '@/features/auth/types/errors';
import { ACCESS_TOKEN_KEY } from '@/features/auth/constants/cookie/tokens';

const initialState = {
  errors: null as null | LoginFormFieldErrors,
  tokens: {
    access: Cookie.get(ACCESS_TOKEN_KEY),
  } as {
    access?: LoginResponseDto['token'];
  },
  userProfile: null as null | LoginResponseDto['profile'],
};

const slice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    logout: (state) => {
      Cookie.remove(ACCESS_TOKEN_KEY);
      state.tokens = {};
      state.errors = initialState.errors;
      state.userProfile = initialState.userProfile;
    },
    resetErrors: (state) => {
      state.errors = initialState.errors;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
        state.errors = initialState.errors;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const access = action.payload.token;

        Cookie.set(ACCESS_TOKEN_KEY, access);
        state.tokens = { access };
        state.userProfile = action.payload.profile;
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        const failureData = action.payload?.data;

        if (failureData && isAuthErrors(failureData)) {
          state.errors = failureData.data.errors;
        }
      })
      .addMatcher(
        (action) => authApi.endpoints.logout.matchFulfilled(action) || authApi.endpoints.logout.matchRejected(action),
        (state, action) => {
          Cookie.remove(ACCESS_TOKEN_KEY);
          state.tokens = {};
          state.errors = initialState.errors;
          state.userProfile = initialState.userProfile;
        },
      );
  },
});

export const { logout, resetErrors } = slice.actions;
export const authReducer = slice.reducer;

export const selectIsAuthenticated = (state: RootState) => !!state[AUTH_SLICE_NAME].tokens?.access;

export const errorsSelector = (state: RootState) => state[AUTH_SLICE_NAME].errors;
