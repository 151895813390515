import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, PasswordInput } from '@/shared/components';
import { SetPasswordFormFieldErrors } from '@/features/auth/types';
import { ErrorList } from '@/shared/components/errors';
import { resetErrors } from '@/features/auth/slices';
import { useAppDispatch } from '@/shared/hooks';

import { FormWrapper } from '../form-wrapper';
import { FormTitle } from '../form-title';
import { FormDescription } from '../form-description';
import { setPasswordValidationSchema } from './set-password-validation-schema';

import styles from './set-password-form.module.scss';

export type SetPasswordFormValues = {
  password: string;
};

type ResetPasswordFormProps = {
  disableAction: boolean;
  onSubmit: (v: SetPasswordFormValues) => void;
  errors: null | SetPasswordFormFieldErrors;
};

const formFieldsMap = {
  password: 'password',
} as const;

export const SetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit, disableAction, errors: serverErrors }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(setPasswordValidationSchema),
    defaultValues: {
      password: '',
    },
  });

  useEffect(() => {
    if (serverErrors?.fieldErrors) {
      serverErrors?.fieldErrors[formFieldsMap.password]?.forEach((message) => {
        setError(formFieldsMap.password, { message });
      });
    }
  }, [serverErrors, setError]);

  const resetErrorsOnChange = () => {
    if (commonErrors?.length) {
      dispatch(resetErrors());
    }
  };

  const passwordErrors = [errors[formFieldsMap.password]?.message];
  const commonErrors = serverErrors?.nonFieldErrors;

  return (
    <FormWrapper>
      <form className={styles['form']} noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormTitle title="Reset password" />
        <FormDescription description="Password should have at least 8 characters including a lowercase letter, an uppercase letter, and a number" />

        <Controller
          name={formFieldsMap.password}
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              containerClassName={styles['password-input']}
              placeholder="Password"
              errors={passwordErrors}
              onChange={(e) => {
                field.onChange(e);
                resetErrorsOnChange();
              }}
            />
          )}
        />

        <Button fullWidth type="submit" disabled={disableAction}>
          Save
        </Button>

        {!!commonErrors?.length && (
          <ErrorList containerClassName={styles['common-errors-container']} errors={commonErrors} />
        )}
      </form>
    </FormWrapper>
  );
};
