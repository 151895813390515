import { FC, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { SentryProvider } from '@/providers/sentry-provider';
import { store } from '@/setup';
import ModalProvider from '@/providers/modal-provider';
import { ToastContainer } from '@/shared/components';

type AppProvidersProps = {
  children: ReactNode;
};

export const AppProviders: FC<AppProvidersProps> = ({ children }) => {
  return (
    <SentryProvider>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <ModalProvider>{children}</ModalProvider>
        </ReduxProvider>
      </BrowserRouter>
      <ToastContainer />
    </SentryProvider>
  );
};
