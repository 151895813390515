import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import styles from './button.module.scss';

export type Size = 'large' | 'medium' | 'small';
export type Color = 'primary' | 'secondary' | 'white' | 'negative';
export type Variant = 'outlined' | 'contained' | 'icon' | 'text';

export type ButtonProps = {
  color?: Color;
  variant?: Variant;
  size?: Size;
  fullWidth?: boolean;
  noPadding?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = 'black',
      variant = 'contained',
      size = 'large',
      fullWidth,
      className: classNameProp,
      noPadding = false,
      ...restProps
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        {...restProps}
        className={clsx(classNameProp, styles['button'], styles[color], styles[variant], styles[size], {
          [styles['full-width']]: fullWidth,
          [styles['no-padding']]: noPadding,
        })}
      />
    );
  },
);
