import { DEFAULT_TABLE_ITEMS_SIZE, FILTERS_TABLE_KEY, PAGINATION_KEY, SORT_TABLE_KEY } from '@/shared/constants';
import { getCurrentMonthDateRange, jsonStringify } from '@/shared/utils';
import { JobHistoryColumnEnum } from '@/features/job-history/types';
import { OrderByEnum } from '@/shared/types/order';
import { InvoicesColumnEnum } from '@/features/invoices/types';

const [firstDay, lastDay] = getCurrentMonthDateRange();

const currentMonthFilter = { dateRange: [firstDay.toUTCString(), lastDay.toUTCString()] };

export const jobsHistoryDefaultQueryParams = {
  [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
  [SORT_TABLE_KEY]: jsonStringify({ column: JobHistoryColumnEnum.CREATED, order: OrderByEnum.DESC }),
  [FILTERS_TABLE_KEY]: jsonStringify(currentMonthFilter),
};

export const customersDefaultQueryParams = {
  [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
  [SORT_TABLE_KEY]: jsonStringify({}),
  [FILTERS_TABLE_KEY]: jsonStringify({}),
};

export const receiveCustomerJobsHistoryDefaultQueryParams = (userId: string) => ({
  [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
  [SORT_TABLE_KEY]: jsonStringify({ column: JobHistoryColumnEnum.CREATED, order: OrderByEnum.DESC }),
  [FILTERS_TABLE_KEY]: jsonStringify({ userId }),
});

export const handsDefaultQueryParams = {
  [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
  [SORT_TABLE_KEY]: jsonStringify({}),
  [FILTERS_TABLE_KEY]: jsonStringify({}),
};

export const receiveHandJobsHistoryDefaultQueryParams = (userId: string) => ({
  [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
  [SORT_TABLE_KEY]: jsonStringify({ column: JobHistoryColumnEnum.CREATED, order: OrderByEnum.DESC }),
  [FILTERS_TABLE_KEY]: jsonStringify({ userId }),
});

export const dashboardPageDefaultQueryParams = {
  [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
  [SORT_TABLE_KEY]: jsonStringify({ column: InvoicesColumnEnum.CREATED, order: OrderByEnum.DESC }),
  [FILTERS_TABLE_KEY]: jsonStringify(currentMonthFilter),
};
