import { FC, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';

import { useGetCategoriesQuery } from '@/features/categories/services';
import { ArrowDownIcon } from '@/shared/icons';
import { useClickOutside } from '@/shared/hooks';
import { Button, Checkbox, DropdownTrigger } from '@/shared/components';
import { Controller, useForm } from 'react-hook-form';
import { JobSubcategoriesDto } from '@/features/categories/dto';

import styles from './category-filters.module.scss';

enum AllCategoriesStateEnum {
  NON_SELECTED,
  SELECTED,
}

type CategoryFiltersProps = {
  selectedSubcategoryIds?: string[];
  onSubmit: (categories: string[]) => void;
};

export const CategoryFilters: FC<CategoryFiltersProps> = ({ selectedSubcategoryIds, onSubmit: onSubmitProp }) => {
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      allCategories: selectedSubcategoryIds?.length
        ? AllCategoriesStateEnum.NON_SELECTED
        : AllCategoriesStateEnum.SELECTED,
      categories: selectedSubcategoryIds || [],
    },
  });

  const [selectedAccordionSection, setSelectedAccordionSection] = useState('Home');
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 11],
        },
      },
    ],
    placement: 'bottom',
  });

  useClickOutside([referenceElement, popperElement], () => setIsOpenDropdown(false));

  const { data, isLoading, isFetching } = useGetCategoriesQuery();

  const onSubmit = (values: { categories: string[] }) => {
    setIsOpenDropdown(false);
    onSubmitProp(values.categories);

    if (!values.categories.length) {
      setValue('allCategories', AllCategoriesStateEnum.SELECTED);
    }
  };

  const triggerLabel = useMemo(() => {
    if (selectedSubcategoryIds?.length && data) {
      const subcategories = data.reduce((acc, category) => {
        category.jobSubcategories.forEach((subcategory) => {
          acc.push(subcategory);
        });

        return acc;
      }, [] as JobSubcategoriesDto[]);

      return subcategories
        .map(({ label, id }) => (selectedSubcategoryIds.includes(id) ? label : ''))
        .filter(Boolean)
        .join(', ');
    }

    return 'All categories';
  }, [selectedSubcategoryIds, data]);

  return (
    <>
      <DropdownTrigger
        ref={setReferenceElement}
        onClick={() => {
          setIsOpenDropdown((prev) => !prev);
        }}
        label="Category"
        disabled={isLoading || isFetching}
      >
        {isLoading || isFetching ? 'Loading...' : triggerLabel}
      </DropdownTrigger>

      {isOpenDropdown && (
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={styles['dropdown-menu']}
          ref={setPopperElement}
          style={popper.styles['popper']}
        >
          <div className={styles['all-categories-checkbox-container']}>
            <Controller
              name="allCategories"
              control={control}
              render={({ field }) => {
                const isSelected = field.value === AllCategoriesStateEnum.SELECTED;

                return (
                  <Checkbox
                    name={field.name}
                    value={field.value}
                    labelClassName={styles['all-categories-checkbox-label']}
                    label="All categories"
                    defaultChecked={isSelected}
                    checked={isSelected}
                    onChange={() => {
                      if (isSelected) {
                        field.onChange(AllCategoriesStateEnum.NON_SELECTED);
                        return;
                      }

                      field.onChange(AllCategoriesStateEnum.SELECTED);
                      setValue('categories', []);
                    }}
                  />
                );
              }}
            />
          </div>

          <div className={styles['accordion']}>
            {data?.map(({ label, jobSubcategories }, i) => {
              const isSelected = selectedAccordionSection === label;

              return (
                <div key={i} className={styles['accordion-item']}>
                  <button
                    type="button"
                    className={styles['accordion-item-trigger']}
                    onClick={() => setSelectedAccordionSection(label)}
                  >
                    <span className={styles['accordion-item-label']}>{label}</span>
                    <ArrowDownIcon className={clsx(styles['accordion-item-icon'], isSelected && styles['selected'])} />
                  </button>

                  {isSelected && (
                    <Controller
                      key={i}
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className={styles['accordion-item-body']}>
                            {jobSubcategories.map(({ label, id }) => {
                              const isChecked = field.value.includes(id);

                              return (
                                <Checkbox
                                  key={id}
                                  id={id}
                                  label={label}
                                  name={field.name}
                                  value={id}
                                  checked={isChecked}
                                  containerClassName={styles['checkbox']}
                                  onChange={() => {
                                    // toggle
                                    if (isChecked) {
                                      field.onChange(field.value.filter((itemId) => itemId !== id));
                                    } else {
                                      field.onChange(field.value.concat(id));
                                      setValue('allCategories', AllCategoriesStateEnum.NON_SELECTED);
                                    }
                                  }}
                                />
                              );
                            })}
                          </div>
                        );
                      }}
                      name="categories"
                    />
                  )}
                </div>
              );
            })}
          </div>

          <Button type="submit" className={styles['submit-button']}>
            Apply
          </Button>
        </form>
      )}
    </>
  );
};
