import { FC } from 'react';

import { CrossIcon } from '@/shared/icons';
import { ModalBlankWrapperProps } from '@/shared/components';

import { Button } from '../../button';

import style from './modal-header.module.scss';

type ModalHeaderProps = {
  title: string;
  buttonAriaLabel?: string;
  hideModal: ModalBlankWrapperProps['hideModal'];
};

export const ModalHeader: FC<ModalHeaderProps> = ({ title, hideModal, buttonAriaLabel = 'Close the pop-up' }) => {
  return (
    <div className={style['wrapper']}>
      <h4 className={style['title']}>{title}</h4>
      <Button variant="icon" onClick={hideModal} aria-label={buttonAriaLabel}>
        <CrossIcon />
      </Button>
    </div>
  );
};
