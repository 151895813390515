import { Customer, Hand } from '@/features/user-management/dto';

export const normalizeCustomersTableData = (data: Customer[]) =>
  data.map(
    (customer) =>
      ({
        id: customer.id,
        displayId: `#${customer.displayId}`,
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        isDeactivated: customer.isDeactivated,
        avatarUrl: customer.fileInfo?.url,
        isDeleted: customer.isDeleted,
      }) as const,
  );

export const normalizeHandsTableData = (data: Hand[]) =>
  data.map(
    (hand) =>
      ({
        id: hand.id,
        displayId: `#${hand.displayId}`,
        email: hand.email,
        firstName: hand.firstName,
        lastName: hand.lastName,
        isDeactivated: hand.isDeactivated,
        avatarUrl: hand.fileInfo?.url,
        isDeleted: hand.isDeleted,
      }) as const,
  );
