import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { Button } from '@/shared/components';
import { ArrowDownDarkIcon } from '@/shared/icons';
import { handleSort } from '@/shared/utils/sorting';
import { useQueryParams } from '@/shared/hooks';
import { jsonParse } from '@/shared/utils';
import { OrderByEnum } from '@/shared/types/order';
import { QueryParams } from '@/shared/types';

import styles from './sorting.module.scss';

export type SortingProps = {
  ariaLabel?: string;
  sortName: string;
  isDescendingFirst?: boolean;
  disabled?: boolean;
  children: ReactNode;
};

const classNamesMap = {
  [OrderByEnum.ASC]: styles['asc'],
  [OrderByEnum.DESC]: styles['desc'],
  [OrderByEnum.NONE]: styles['none'],
};

export const Sorting: FC<SortingProps> = ({ sortName, isDescendingFirst, disabled, children }) => {
  const { queryParams, setQueryParams } = useQueryParams();

  const orderByParams: QueryParams | null = jsonParse(queryParams.orderBy);

  const isTheSameColumn = orderByParams?.column === sortName;
  const sortDirection = isTheSameColumn ? (orderByParams.order as OrderByEnum) : OrderByEnum.NONE;

  const onSort = () => {
    handleSort(sortName, !!isDescendingFirst, setQueryParams, sortDirection);
  };

  const isOrdered = orderByParams?.column === sortName;

  return (
    <Button onClick={onSort} variant="icon" className={styles['button']} disabled={disabled}>
      <p className={styles['label']}>{children}</p>

      <ArrowDownDarkIcon
        className={clsx(
          styles['icon'],
          isDescendingFirst && styles['desc-first'],
          isOrdered ? classNamesMap[sortDirection] : classNamesMap[OrderByEnum.NONE],
        )}
      />
    </Button>
  );
};
