export const calculateCentsToDollars = (cents: number) => cents / 100;

export const calculateDollarsToCents = (dollars: number) => Math.round(dollars * 100);

export const formatDollars = (dollars: number) => {
  const options = { style: 'currency', currency: 'USD' };
  const formatter = new Intl.NumberFormat('en-US', options);

  return formatter.format(dollars);
};
