import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './table-footer.module.scss';

type TableFooterProps = {
  footerClassName?: string;
  children: ReactNode;
};

export const TableFooter: FC<TableFooterProps> = ({ footerClassName, children }) => {
  return (
    <div role="row" className={clsx(footerClassName, styles['table-footer'])}>
      {children}
    </div>
  );
};
