import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input, Button } from '@/shared/components';
import { APP_LINKS } from '@/configs';
import { ResetPasswordFormFieldErrors } from '@/features/auth/types';
import { ErrorList } from '@/shared/components/errors';
import { resetErrors } from '@/features/auth/slices';
import { useAppDispatch } from '@/shared/hooks';
import { GoBackLink } from '@/features/auth/components/go-back-link';

import { FormWrapper } from '../form-wrapper';
import { FormTitle } from '../form-title';
import { FormDescription } from '../form-description';
import { resetPasswordValidationSchema } from './reset-password-validation-schema';

import styles from './reset-password-form.module.scss';

export type ResetPasswordFormValues = {
  email: string;
};

type ResetPasswordFormProps = {
  disableAction: boolean;
  onSubmit: (v: ResetPasswordFormValues) => void;
  errors: null | ResetPasswordFormFieldErrors;
};

const formFieldsMap = {
  email: 'email',
} as const;

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit, disableAction, errors: serverErrors }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (serverErrors?.fieldErrors) {
      serverErrors?.fieldErrors[formFieldsMap.email]?.forEach((message) => {
        setError(formFieldsMap.email, { message });
      });
    }
  }, [serverErrors, setError]);

  const resetErrorsOnChange = () => {
    if (commonErrors?.length) {
      dispatch(resetErrors());
    }
  };

  const emailErrors = [errors[formFieldsMap.email]?.message];
  const commonErrors = serverErrors?.nonFieldErrors;

  return (
    <FormWrapper>
      <form className={styles['form']} noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormTitle title="Forgot password?" />
        <FormDescription description="Enter your email to reset your password" />

        <Controller
          name={formFieldsMap.email}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              containerClassName={styles['email-input']}
              type="email"
              placeholder="Email"
              errors={emailErrors}
              onChange={(e) => {
                field.onChange(e);
                resetErrorsOnChange();
              }}
            />
          )}
        />

        <Button className={styles['main-action']} fullWidth type="submit" disabled={disableAction}>
          Reset password
        </Button>

        <GoBackLink className={styles['secondary-action']} to={APP_LINKS.LOGIN}>
          Back to login
        </GoBackLink>

        {!!commonErrors?.length && (
          <ErrorList containerClassName={styles['common-errors-container']} errors={commonErrors} />
        )}
      </form>
    </FormWrapper>
  );
};
