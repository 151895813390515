import { FC, lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { componentLoader, LayoutContainer, SuspenseLoading } from '@/shared/components';
import { APP_LINKS, APP_ROUTES } from '@/configs';
import { AuthorizedRouteGuard, UnauthorizedRouteGuard } from '@/shared/guards';

const LoginPage = lazy(() => componentLoader(() => import('@/features/auth/containers/login/login-page')));
const ResetPasswordPage = lazy(() =>
  componentLoader(() => import('@/features/auth/containers/reset-password/reset-password-page')),
);
const SetPasswordPage = lazy(() =>
  componentLoader(() => import('@/features/auth/containers/set-password/set-password-page')),
);
const DashboardPage = lazy(() => componentLoader(() => import('@/features/invoices/containers/dashboard-page')));
const JobHistoryPage = lazy(() =>
  componentLoader(() => import('@/features/job-history/containers/job-history-page/job-history-page')),
);
const JobDetailsPage = lazy(() =>
  componentLoader(() => import('@/features/job-history/containers/job-details-page/job-details-page')),
);
const UserManagementCustomersPage = lazy(() =>
  componentLoader(() => import('@/features/user-management/containers/customers-page/customers-page')),
);
const UserManagementCustomerControlPage = lazy(() =>
  componentLoader(() => import('@/features/user-management/containers/customer-control-page/customer-control-page')),
);
const UserManagementHandsPage = lazy(() =>
  componentLoader(() => import('@/features/user-management/containers/hands-page/hands-page')),
);
const UserManagementHandControlPage = lazy(() =>
  componentLoader(() => import('@/features/user-management/containers/hand-control-page/hand-control-page')),
);

export const AppRoutes: FC = () => {
  const defaultUnauthorizedRoute = APP_LINKS.LOGIN;
  const defaultAuthorizedRoute = APP_LINKS.DASHBOARD;

  return (
    <Routes>
      <Route element={<LayoutContainer />}>
        <Route
          element={
            <Suspense fallback={<SuspenseLoading opacityTextAnimation cover />}>
              <Outlet />
            </Suspense>
          }
        >
          {/* Only authorized users (private routes) */}
          <Route element={<AuthorizedRouteGuard redirect={defaultUnauthorizedRoute} />}>
            <Route path={APP_ROUTES.DASHBOARD} element={<DashboardPage />} />
            <Route path={APP_ROUTES.JOB_HISTORY}>
              <Route index element={<JobHistoryPage />} />
              <Route path={APP_ROUTES.JOB_HISTORY__JOB_DETAILS} element={<JobDetailsPage />} />
            </Route>
            <Route path={APP_ROUTES.USER_MANAGEMENT}>
              <Route index element={<Navigate to={defaultAuthorizedRoute} replace />} />

              <Route path={APP_ROUTES.USER_MANAGEMENT__CUSTOMERS}>
                <Route index element={<UserManagementCustomersPage />} />

                <Route
                  path={APP_ROUTES.USER_MANAGEMENT__CUSTOMERS__CONTROL}
                  element={<UserManagementCustomerControlPage />}
                />
              </Route>

              <Route path={APP_ROUTES.USER_MANAGEMENT__HANDS}>
                <Route index element={<UserManagementHandsPage />} />

                <Route path={APP_ROUTES.USER_MANAGEMENT__HANDS__CONTROL} element={<UserManagementHandControlPage />} />
              </Route>
            </Route>

            <Route path="*" element={<Navigate to={defaultAuthorizedRoute} replace />} />
          </Route>

          {/* Only unauthorized users (public routes) */}
          <Route element={<UnauthorizedRouteGuard redirect={defaultAuthorizedRoute} />}>
            <Route path={APP_ROUTES.LOGIN} element={<LoginPage />} />
            <Route path={APP_ROUTES.RESET_PASSWORD}>
              <Route index element={<ResetPasswordPage />} />
              <Route path={APP_ROUTES.RESET_PASSWORD__SET_PASSWORD} element={<SetPasswordPage />} />
            </Route>

            <Route path="*" element={<Navigate to={defaultUnauthorizedRoute} replace />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
