const REATTEMPT_AGAIN_TIME = 1500;

export const componentLoader = (lazyComponent: any, attemptsLeft = 3): Promise<any> => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(() => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            // reject(error);
            // return;
            console.log('Reload.');
            document.location.reload();
          }
          console.log(`Lazy load attempt. Attempts left: ${attemptsLeft - 1}`);
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, REATTEMPT_AGAIN_TIME);
      });
  });
};
