import { FC, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@/shared/hooks';
import { Button, Checkbox, DropdownTrigger } from '@/shared/components';
import { Controller, useForm } from 'react-hook-form';

import styles from './university-filters.module.scss';
import { useGetUniversitiesQuery } from '../../services';

enum AllUniversitiesTypesStateEnum {
  NON_SELECTED,
  SELECTED,
}

type UniversityFiltersProps = {
  selectedUniversityTypes?: string[];
  onSubmit: (university: string[]) => void;
};

export const UniversityFilters: FC<UniversityFiltersProps> = ({ selectedUniversityTypes, onSubmit: onSubmitProp }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 11],
        },
      },
    ],
    placement: 'bottom',
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      allUniversityTypes: AllUniversitiesTypesStateEnum.NON_SELECTED,
      universityTypes: selectedUniversityTypes || [],
    },
  });

  useClickOutside([referenceElement, popperElement], () => setIsOpenDropdown(false));

  const { data, isLoading, isFetching } = useGetUniversitiesQuery();

  const onSubmit = (values: { universityTypes: string[] }) => {
    setIsOpenDropdown(false);
    onSubmitProp(values.universityTypes);

    if (!values.universityTypes.length) {
      setValue('allUniversityTypes', AllUniversitiesTypesStateEnum.SELECTED);
    }
  };

  const triggerLabel = useMemo(() => {
    if (selectedUniversityTypes?.length && data) {
      return data
        .map((item) => (selectedUniversityTypes.includes(item) ? item : ''))
        .filter(Boolean)
        .join(', ');
    }

    return 'All types';
  }, [selectedUniversityTypes, data]);

  return (
    <>
      <DropdownTrigger
        ref={setReferenceElement}
        onClick={() => {
          setIsOpenDropdown((prev) => !prev);
        }}
        label="University"
        disabled={isLoading || isFetching}
      >
        {isLoading || isFetching ? 'Loading...' : triggerLabel}
      </DropdownTrigger>

      {isOpenDropdown && (
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={styles['dropdown-menu']}
          ref={setPopperElement}
          style={popper.styles['popper']}
        >
          <div className={styles['all-universities-checkbox-container']}>
            <Controller
              name="allUniversityTypes"
              control={control}
              render={({ field }) => {
                const isSelected = field.value === AllUniversitiesTypesStateEnum.SELECTED;

                return (
                  <Checkbox
                    name={field.name}
                    value={field.value}
                    labelClassName={styles['all-universities-checkbox-label']}
                    label="All types"
                    defaultChecked={isSelected}
                    checked={isSelected}
                    onChange={() => {
                      if (isSelected) {
                        field.onChange(AllUniversitiesTypesStateEnum.NON_SELECTED);
                        return;
                      }

                      field.onChange(AllUniversitiesTypesStateEnum.SELECTED);
                      setValue('universityTypes', []);
                    }}
                  />
                );
              }}
            />
          </div>

          <div className={styles['all-universities-checkbox-container']}>
            <Controller
              name="universityTypes"
              control={control}
              render={({ field }) => {
                return (
                  <div>
                    {data?.map((item, index) => {
                      const isChecked = field.value.includes(item);

                      return (
                        <Checkbox
                          key={index}
                          id={item}
                          label={item}
                          name={field.name}
                          value={item}
                          checked={isChecked}
                          containerClassName={styles['checkbox']}
                          onChange={() => {
                            // toggle
                            if (isChecked) {
                              field.onChange(field.value.filter((itemId) => itemId !== item));
                            } else {
                              field.onChange(field.value.concat(item));
                              setValue('allUniversityTypes', AllUniversitiesTypesStateEnum.NON_SELECTED);
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                );
              }}
            />
          </div>

          <Button type="submit" className={styles['submit-button']}>
            Apply
          </Button>
        </form>
      )}
    </>
  );
};
