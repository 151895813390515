import { FC, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';

import { useClickOutside } from '@/shared/hooks';
import { Button } from '@/shared/components';
import { ArrowDownIcon } from '@/shared/icons';

import styles from './link-dropdown.module.scss';

type LinkDropdownProps = {
  children: ReactNode;
  triggerLabel: string;
  triggerClassName?: string;
  isActive: boolean;
  isOpenDropdown: boolean;
  setIsOpenDropdown: (isOpen: boolean) => void;
};

export const LinkDropdown: FC<LinkDropdownProps> = ({
  children,
  triggerLabel,
  triggerClassName,
  isActive,
  isOpenDropdown,
  setIsOpenDropdown,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'bottom',
  });

  useClickOutside([referenceElement, popperElement], () => setIsOpenDropdown(false));

  return (
    <>
      <Button
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        ref={setReferenceElement}
        className={clsx(triggerClassName)}
      >
        <div className={styles['dropdown-trigger']}>
          <span>{triggerLabel}</span>
          <ArrowDownIcon
            width="1.5rem"
            height="1.5rem"
            className={clsx(styles['dropdown-trigger-icon'], {
              [styles['open']]: isOpenDropdown,
              [styles['active']]: isActive,
            })}
          />
        </div>
      </Button>
      {isOpenDropdown && (
        <div
          className={styles['dropdown-menu']}
          ref={setPopperElement}
          style={popper.styles['popper']}
          {...popper.attributes}
        >
          {children}
        </div>
      )}
    </>
  );
};
