import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import clsx from 'clsx';

import { Button, ModalBlankWrapper, ModalHeader } from '@/shared/components';

import styles from './modal-with-actions.module.scss';

export type ModalWithActionsProps = {
  hideModal: () => void;
  headerTitle?: string;
  confirmButtonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  confirmButtonClassName?: string;
  confirmLabel?: string;
  onConfirm?: () => void;
  disableConfirmButton?: boolean;
  cancelLabel?: string;
  onCancel?: () => void;
  disableCancelButton?: boolean;
  cancelButtonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  containerClassName?: string;
  children?: ReactNode;
  disableClosing?: boolean;
};

export const ModalWithActions: FC<ModalWithActionsProps> = ({
  hideModal,
  headerTitle,
  confirmLabel,
  onConfirm,
  disableConfirmButton,
  confirmButtonType,
  confirmButtonClassName,
  cancelButtonType,
  onCancel,
  disableCancelButton,
  cancelLabel,
  containerClassName,
  children,
  disableClosing,
}) => {
  const onHideModal = disableClosing ? () => undefined : hideModal;

  return (
    <ModalBlankWrapper isOpen hideModal={onHideModal}>
      <div className={clsx(styles['container'], containerClassName)}>
        {headerTitle && <ModalHeader title={headerTitle} hideModal={onHideModal} />}

        <div>{children}</div>

        {(confirmLabel || cancelLabel) && (
          <div className={styles['footer']}>
            {cancelLabel && (
              <Button
                color="secondary"
                type={cancelButtonType || 'button'}
                onClick={typeof onCancel === 'function' ? onCancel : onHideModal}
                disabled={disableCancelButton}
              >
                Cancel
              </Button>
            )}
            {confirmLabel && (
              <Button
                className={confirmButtonClassName}
                type={confirmButtonType || 'button'}
                disabled={!!disableConfirmButton}
                onClick={onConfirm}
              >
                {confirmLabel}
              </Button>
            )}
          </div>
        )}
      </div>
    </ModalBlankWrapper>
  );
};
