import { FC, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { usePopper } from 'react-popper';
import { addHours, addMinutes, addSeconds, format } from 'date-fns';

import { MONTH_DAY_YEAR_DATE_FORMAT } from '@/shared/constants';
import { Button, DropdownTrigger } from '@/shared/components';
import { useClickOutside } from '@/shared/hooks';
import { CalendarIcon } from '@/shared/icons';

import styles from './datepicker-filter.module.scss';

type DatepickerFilterProps = {
  selectedRange?: [string, string];
  onSubmit: (dateRange?: [string, string]) => void;
};

const EMPTY_RANGE: [null, null] = [null, null];

export const DatepickerFilter: FC<DatepickerFilterProps> = ({ selectedRange, onSubmit }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 11],
        },
      },
    ],
    placement: 'bottom',
  });

  useClickOutside([referenceElement, popperElement], () => setIsOpenDropdown(false));

  const selectedStartDate = selectedRange?.[0];
  const selectedEndDate = selectedRange?.[1];
  const hasSelectedRange = !!selectedStartDate && !!selectedEndDate;

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
    hasSelectedRange ? [new Date(selectedStartDate), new Date(selectedEndDate)] : EMPTY_RANGE,
  );
  const [startDate, endDate] = dateRange;

  const dateRangeLabel = hasSelectedRange
    ? `${format(selectedStartDate, MONTH_DAY_YEAR_DATE_FORMAT)} - ${format(selectedEndDate, MONTH_DAY_YEAR_DATE_FORMAT)}`
    : 'All Dates';

  const handleApply = () => {
    setIsOpenDropdown(false);

    if (!startDate || !endDate) {
      onSubmit();
      return;
    }

    const utcStartDate = startDate.toUTCString();
    const endDateWithWholeDateTime = addHours(addMinutes(addSeconds(endDate, 59), 59), 23);
    const utcEndDate = endDateWithWholeDateTime.toUTCString();

    onSubmit([utcStartDate, utcEndDate]);
  };

  const handleReset = () => {
    setDateRange(EMPTY_RANGE);
  };

  return (
    <>
      <DropdownTrigger
        ref={setReferenceElement}
        onClick={() => {
          setIsOpenDropdown((prev) => !prev);
        }}
        label="Date range"
        endIcon={<CalendarIcon />}
      >
        {dateRangeLabel}
      </DropdownTrigger>

      {isOpenDropdown && (
        <div className={styles['dropdown-menu']} ref={setPopperElement} style={popper.styles['popper']}>
          <ReactDatePicker
            inline
            selectsRange={true}
            startDate={startDate}
            monthsShown={2}
            endDate={endDate}
            onChange={(range) => {
              setDateRange(range);
            }}
            isClearable={true}
          />

          <div className={styles['actions-container']}>
            <Button variant="text" onClick={handleReset}>
              Reset
            </Button>
            <Button onClick={handleApply}>Apply</Button>
          </div>
        </div>
      )}
    </>
  );
};
