import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '@/shared/types';

type SetQueryParams = (params: QueryParams | ((prev: QueryParams) => QueryParams)) => void;

export const useQueryParams = () => {
  const [queryOptions, setQueryOptions] = useSearchParams();

  const serializeQueryParamsToObject = (query: URLSearchParams): QueryParams => Object.fromEntries(query.entries());

  const setQueryParams: SetQueryParams = useCallback(
    (query): void => {
      setQueryOptions((prevQuery) => {
        const serializedPrevQuery = serializeQueryParamsToObject(prevQuery);

        if (typeof query === 'function') {
          return {
            ...serializedPrevQuery,
            ...query(serializedPrevQuery),
          };
        }

        return {
          ...serializedPrevQuery,
          ...query,
        };
      });
    },
    [setQueryOptions],
  );

  return {
    queryParams: serializeQueryParamsToObject(queryOptions),
    setQueryParams,
  };
};

export default useQueryParams;
