export enum OrderByEnum {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export interface ColumnOrderBy<C extends string = string> {
  column: C;
  order: OrderByEnum;
}
