import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@/features/auth';

type AuthorizedRouteGuardProps = {
  redirect: string;
};

export const AuthorizedRouteGuard: FC<AuthorizedRouteGuardProps> = ({ redirect }) => {
  const { isAuthorized } = useAuth();

  return isAuthorized ? <Outlet /> : <Navigate to={redirect} replace />;
};
