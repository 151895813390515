import { FC, ReactNode } from 'react';

import styles from './table-wrapper.module.scss';

type PageWrapperProps = {
  children: ReactNode;
};

export const TableWrapper: FC<PageWrapperProps> = ({ children }) => (
  <section className={styles['table-container']}>{children}</section>
);
