import { forwardRef, InputHTMLAttributes, ReactNode, WheelEvent } from 'react';
import clsx from 'clsx';

import { ErrorList } from '../../errors';
import styles from './input.module.scss';

export type InputProps = {
  errors?: (string | undefined)[];
  description?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onIconClick?: () => void;
  containerClassName?: string;
  inputContainerClassName?: string;
  label?: string;
  hideArrowsFromNumberInput?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      errors,
      description,
      startIcon,
      endIcon,
      onIconClick,
      className: classNameProp,
      containerClassName,
      inputContainerClassName,
      label,
      hideArrowsFromNumberInput,
      onWheel: onWheelProp,
      type: typeProps,
      ...restProps
    },
    ref,
  ) => {
    const hasError = !!errors?.filter(Boolean).length;

    const onWheel = (e: WheelEvent<HTMLInputElement>) => {
      if (typeof onWheelProp === 'function') {
        onWheelProp(e);
        return;
      }

      // disabled change numbers of input on scroll
      if (hideArrowsFromNumberInput && typeProps === 'number') {
        e.currentTarget.blur();
      }
    };

    return (
      <div className={clsx(styles['container'], containerClassName)}>
        <div className={clsx(styles['input-container'], inputContainerClassName)}>
          {startIcon && (
            <div className={styles['start-icon']} onClick={onIconClick}>
              {startIcon}
            </div>
          )}

          {label && <label className={styles['label']}>{label}</label>}

          <input
            ref={ref}
            {...restProps}
            type={typeProps}
            onWheel={onWheel}
            className={clsx(
              classNameProp,
              styles['input'],
              hideArrowsFromNumberInput && styles['hide-arrows-from-number-input'],
              startIcon && styles['start-icon-shown'],
              endIcon && styles['end-icon-shown'],
              hasError && styles['has-error'],
            )}
          />

          {endIcon && (
            <div className={styles['end-icon']} onClick={onIconClick}>
              {endIcon}
            </div>
          )}
        </div>

        <div className={styles['text-container']}>
          {hasError && <ErrorList errors={errors} containerClassName={styles['error-list']} />}
          {description && <div className={styles['description']}>{description}</div>}
        </div>
      </div>
    );
  },
);
