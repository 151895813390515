import { useTypedSelector } from '@/shared/hooks';
import { selectIsAuthenticated } from '@/features/auth/slices';
import { useLogoutMutation } from '@/features/auth/services';

export const useAuth = () => {
  const [logout] = useLogoutMutation();

  return {
    logout,
    isAuthorized: useTypedSelector(selectIsAuthenticated),
  };
};
