import { FC, ReactNode } from 'react';
import style from './suspense-wrapper.module.scss';
import clsx from 'clsx';

type SuspenseWrapperProps = {
  children: ReactNode;
  cover?: boolean;
};

export const SuspenseWrapper: FC<SuspenseWrapperProps> = ({ children, cover }) => {
  return <div className={clsx(style['wrapper'], cover && style['cover'])}>{children}</div>;
};

export default SuspenseWrapper;
