import { FC, HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './table-row-group.module.scss';

type TableRowGroupProps = HTMLAttributes<HTMLDivElement> & {
  rowGroupClassName?: string;
  children: ReactNode;
  sticky?: boolean;
};

export const TableRowGroup: FC<TableRowGroupProps> = ({ rowGroupClassName, children, sticky, ...restProps }) => {
  return (
    <div
      role="rowgroup"
      className={clsx(rowGroupClassName, styles['row-group'], {
        [styles['sticky']]: sticky,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};
