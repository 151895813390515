import { FC } from 'react';

import styles from './table-no-data-cell.module.scss';

type TableNoDataProps = {
  text: string;
};

export const TableNoData: FC<TableNoDataProps> = ({ text }) => {
  return <p className={styles['text']}>{text}</p>;
};
