import { FC } from 'react';

import { FormValues, LoginForm } from '@/features/auth/components/login-form/login-form';
import { useHead, useTypedSelector } from '@/shared/hooks';
import { makePageTitle } from '@/shared/utils';
import { useLoginMutation } from '@/features/auth/services/auth';
import { errorsSelector } from '@/features/auth/slices';
import { PageWrapper } from '@/features/auth/components';

const pageConfig = { title: makePageTitle('Login') };

export const LoginPage: FC = () => {
  useHead(pageConfig);

  const errors = useTypedSelector(errorsSelector);

  const [login, { isLoading }] = useLoginMutation();

  const handleSubmit = (values: FormValues) => {
    login(values);
  };

  return (
    <PageWrapper>
      <LoginForm disableAction={isLoading} onSubmit={handleSubmit} errors={errors} />
    </PageWrapper>
  );
};

export default LoginPage;
