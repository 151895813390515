import { APP_LINKS } from '@/configs';

export const linkConfig = [
  {
    label: 'Dashboard',
    link: APP_LINKS.DASHBOARD,
  },
  {
    label: 'Jobs History',
    link: APP_LINKS.JOB_HISTORY,
  },
  {
    label: 'User management',
    links: [
      {
        label: 'Customers',
        link: APP_LINKS.USER_MANAGEMENT__CUSTOMERS,
      },
      {
        label: 'Hands',
        link: APP_LINKS.USER_MANAGEMENT__HANDS,
      },
    ],
  },
];
