import { ResponseType, SystemTypeError } from '@/shared/types';
import { isFetchBaseQueryErrorType } from '@/shared/utils/store';

export const isSystemTypeError = (error: unknown): error is { data: ResponseType<SystemTypeError> } => {
  if (isFetchBaseQueryErrorType(error)) {
    const systemErrorData = error.data as ResponseType<SystemTypeError>;

    return systemErrorData?.data?.type === 'SYSTEM';
  }

  return false;
};
