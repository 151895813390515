import { FC, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { Button, Logo } from '@/shared/components';
import { APP_LINKS } from '@/configs';
import { LogoutIcon } from '@/shared/icons';
import { useAuth } from '@/features/auth';

import { linkConfig } from './link-config';
import { LinkDropdown } from './link-dropdown';
import { NavLink } from './nav-link';

import styles from './authorized-header.module.scss';

const USER_MANAGEMENT_ROUTES: string[] = [APP_LINKS.USER_MANAGEMENT__CUSTOMERS, APP_LINKS.USER_MANAGEMENT__HANDS];

export const AuthorizedHeader: FC = () => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const { pathname } = useLocation();

  const isUserManagementRoute = !!USER_MANAGEMENT_ROUTES.filter((route) => route.match(pathname)).length;

  const { logout } = useAuth();

  return (
    <header className={styles['header']}>
      <Link className={styles['logo-link']} to={APP_LINKS.DASHBOARD}>
        <Logo />
      </Link>

      <nav className={styles['navigation']}>
        <ul className={styles['list']}>
          {linkConfig.map(({ label, link, links }, i) => (
            <li key={i} className={styles['list-item']}>
              {links ? (
                <LinkDropdown
                  isOpenDropdown={isOpenDropdown}
                  setIsOpenDropdown={setIsOpenDropdown}
                  triggerLabel={label}
                  isActive={isUserManagementRoute}
                  triggerClassName={clsx(styles['list-item-link'], isUserManagementRoute && styles['active'])}
                >
                  {links.map(({ link, label }, i) => (
                    <NavLink
                      key={i}
                      className={styles['list-item-link']}
                      activeClassName={styles['active']}
                      to={link}
                      onClick={() => setIsOpenDropdown(false)}
                    >
                      {label}
                    </NavLink>
                  ))}
                </LinkDropdown>
              ) : (
                <NavLink key={i} className={styles['list-item-link']} activeClassName={styles['active']} to={link}>
                  {label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>

      <Button variant="text" noPadding className={styles['logout-button']} onClick={logout}>
        <LogoutIcon className={styles['logout-button-icon']} width="1.5rem" height="1.5rem" />
        <span className={styles['logout-button-label']}>Log out</span>
      </Button>
    </header>
  );
};
