import { api } from '@/setup/redux/query';
import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  RESET_PASSOWRD_ENDPOINT,
  SET_PASSOWRD_ENDPOINT,
} from '@/features/auth/services/endpoints';
import {
  LoginRequestDto,
  LoginResponseDto,
  ResetPasswordRequestDto,
  ResetPasswordSuccessResponseDto,
  SetPasswordRequestDto,
  SetPasswordSuccessResponseDto,
} from '@/features/auth/dto';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseDto, LoginRequestDto>({
      query: (credentials) => ({
        url: LOGIN_ENDPOINT,
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: LOGOUT_ENDPOINT,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordSuccessResponseDto, ResetPasswordRequestDto>({
      query: (credentials) => ({
        url: RESET_PASSOWRD_ENDPOINT,
        method: 'POST',
        body: credentials,
      }),
    }),
    setPassword: builder.mutation<SetPasswordSuccessResponseDto, SetPasswordRequestDto>({
      query: (credentials) => ({
        url: SET_PASSOWRD_ENDPOINT,
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useResetPasswordMutation, useSetPasswordMutation } = authApi;
