import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@/features/auth';

type UnauthorizedRouteGuardProps = {
  redirect: string;
};

export const UnauthorizedRouteGuard: FC<UnauthorizedRouteGuardProps> = ({ redirect }) => {
  const { isAuthorized } = useAuth();

  return !isAuthorized ? <Outlet /> : <Navigate to={redirect} replace />;
};
