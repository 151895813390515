import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { UnauthorizedHeader } from './unauthorized-header/unauthorized-header';

import styles from './unauthorized-layout.module.scss';

export const UnauthorizedLayout: FC = () => {
  return (
    <div className={styles['container']}>
      <UnauthorizedHeader />
      <Outlet />
    </div>
  );
};
