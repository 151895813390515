import { FC } from 'react';
import SuspenseWrapper from '../suspense-wrapper/suspense-wrapper';

import styles from './suspense-loading.module.scss';
import clsx from 'clsx';

type SuspenseLoadingProps = {
  opacityTextAnimation?: boolean;
  cover?: boolean;
};

export const SuspenseLoading: FC<SuspenseLoadingProps> = ({ cover, opacityTextAnimation }) => {
  return (
    <SuspenseWrapper cover={cover}>
      <h1
        className={clsx(styles['title'], {
          [styles['opacity-animation']]: opacityTextAnimation,
        })}
      >
        Loading...
      </h1>
    </SuspenseWrapper>
  );
};
