import { FC } from 'react';
import clsx from 'clsx';

import { Avatar } from '@/shared/components';

import styles from './avatar-with-full-name.module.scss';

type AvatarWithFullNameProps = {
  avatarUrl?: string;
  firstName: string;
  lastName: string;
  size?: number;
  ellipsis?: boolean;
};

export const AvatarWithFullName: FC<AvatarWithFullNameProps> = ({
  size = 32,
  avatarUrl,
  firstName,
  lastName,
  ellipsis,
}) => {
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className={styles['container']}>
      <Avatar size={size} avatarUrl={avatarUrl} />

      <p className={clsx(styles['full-name'], ellipsis && styles['ellipsis'])} title={fullName}>
        {fullName}
      </p>
    </div>
  );
};
