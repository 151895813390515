import { ComponentType, createContext, FC, ReactNode, useCallback, useState } from 'react';

// TODO[REFACTORING]: specify more safety type then `any` for the `ComponentProps` type
/* eslint-disable @typescript-eslint/no-explicit-any */
type ComponentProps = any;
type AdditionalModalProps = {
  hideModal: HideModalFn;
};
export type ModalComponent<P = ComponentProps> = ComponentType<P>;
type ModalState = Pick<ModalProviderState, 'component' | 'props'>;
export type ShowModalFn = (component: ComponentProps, props: ComponentProps) => void;
export type HideModalFn = () => void;

interface ModalProviderState<P = ComponentProps> {
  component: ModalComponent<P> | null;
  props: P;
  showModal: ShowModalFn;
  hideModal: HideModalFn;
}

const initialState: ModalProviderState = {
  component: null,
  props: {},
  showModal: () => undefined,
  hideModal: () => undefined,
};

export const ModalContext = createContext(initialState);

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>({
    component: null,
    props: {},
  });

  const showModal = useCallback(
    <C extends ComponentType<P & AdditionalModalProps>, P>(
      // TODO[REFACTORING]: `component` should only accept expected props. It accepts its expected props and any others in the current implementation.
      component: C,
      props: P,
    ): void => {
      setModalState({
        component,
        props,
      });
    },
    [],
  );

  const hideModal: HideModalFn = useCallback(() => {
    setModalState({
      component: null,
      props: {},
    });
  }, []);

  const { component: ModalComponent, props: modalComponentProps } = modalState;

  const value = {
    ...modalState,
    showModal,
    hideModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}

      {!!ModalComponent && <ModalComponent {...modalComponentProps} hideModal={hideModal} />}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
