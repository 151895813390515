import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Logo } from '@/shared/components';
import { APP_LINKS } from '@/configs';

import styles from './unauthorized-header.module.scss';

export const UnauthorizedHeader: FC = () => {
  return (
    <header className={styles['header']}>
      <Link to={APP_LINKS.LOGIN} className={styles['header-link']}>
        <Logo />
      </Link>
    </header>
  );
};
