import { combineReducers } from 'redux';

import { AUTH_SLICE_NAME, authReducer } from '@/features/auth/slices';

import { api } from '@/setup/redux/query';

export type RootStore = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [AUTH_SLICE_NAME]: authReducer,
});

export default rootReducer;
