import { format } from 'date-fns';

import { MONTH_DAY_TIME_DATE_FORMAT, TIME_DATE_FORMAT } from '@/shared/constants';

export const makeDateLabelByPeriod = (dateStart: string, dateEnd: string, isPeriod: boolean) =>
  isPeriod
    ? `${format(dateStart, MONTH_DAY_TIME_DATE_FORMAT)} - ${format(dateEnd, TIME_DATE_FORMAT)}`
    : `${format(dateStart, MONTH_DAY_TIME_DATE_FORMAT)} - ${format(dateEnd, MONTH_DAY_TIME_DATE_FORMAT)}`;

export const dateToLocalTimeZone = (date: string) => new Date(date).toISOString();

export const getCurrentMonthDateRange = (): [Date, Date] => {
  const currentDate = new Date(); // Get the current date

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  return [firstDayOfMonth, lastDayOfMonth];
};
