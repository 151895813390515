import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './table-body-row.module.scss';

type TableHeadRowProps = {
  bodyRowClassName?: string;
  children: ReactNode;
};

export const TableBodyRow: FC<TableHeadRowProps> = ({ bodyRowClassName, children }) => {
  return (
    <div role="row" className={clsx(bodyRowClassName, styles['table-body-row'])}>
      {children}
    </div>
  );
};
