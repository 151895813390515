import { ComponentType, FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Sorting, SortingProps } from '@/shared/components/sorting';

import styles from './table-head.module.scss';

type TableSortingProps = {
  sortName?: string;
  width?: number;
  isDescendingFirst?: boolean;
  SortingComponent?: ComponentType<SortingProps>;
  disableSorting?: boolean;
};

type TableHeadProps = {
  headClassName?: string;
  children: ReactNode;
} & TableSortingProps;

export const TableHead: FC<TableHeadProps> = ({
  headClassName,
  width,
  children,
  sortName,
  isDescendingFirst,
  SortingComponent = Sorting,
  disableSorting,
}) => {
  const role = 'columnheader';
  const style = { width, minWidth: width, maxWidth: width };

  if (sortName) {
    return (
      <div className={clsx(headClassName, styles['table-head'])} role={role} style={style}>
        <SortingComponent disabled={disableSorting} sortName={sortName} isDescendingFirst={isDescendingFirst}>
          {children}
        </SortingComponent>
      </div>
    );
  }

  return (
    <div role={role} className={clsx(headClassName, styles['table-head'])} style={style}>
      {children}
    </div>
  );
};
