import { FC } from 'react';
import SuspenseWrapper from '../suspense-wrapper/suspense-wrapper';
import { SOMETHING_WENT_WRONG_ERROR } from '@/shared/constants';
import style from './suspense-error.module.scss';

type SuspenseErrorProps = {
  cover?: boolean;
  error?: string;
  description?: string;
};

export const SuspenseError: FC<SuspenseErrorProps> = ({ cover, error = SOMETHING_WENT_WRONG_ERROR, description }) => {
  return (
    <SuspenseWrapper cover={cover}>
      <h1 className={style['title']}>{error}</h1>
      {description && <p className={style['description']}>{description}</p>}
    </SuspenseWrapper>
  );
};

export default SuspenseError;
