import { FC, useCallback, useEffect, useState } from 'react';
import { useBoolean } from 'react-use';

import { Button } from '@/shared/components';
import { isResetPasswordEmailSentError } from '@/features/auth/utils';
import { useResetPasswordMutation } from '@/features/auth/services';

import styles from './resend-email-countdown-button.module.scss';

type ResendEmailCountdownButtonProps = {
  userEnteredEmail: string | null;
};

const TIMER_IN_SECONDS = 60;
const INTERVAL_DELAY = 1000;

export const ResendEmailCountdownButton: FC<ResendEmailCountdownButtonProps> = ({ userEnteredEmail }) => {
  const [timer, setTimer] = useState(TIMER_IN_SECONDS);
  const [isTimerOver, setIsTimerOver] = useBoolean(false);

  const startTimer = useCallback(
    (intervalId: NodeJS.Timer) => {
      setTimer((prevCount) => {
        if (prevCount > 1) {
          setIsTimerOver(false);
          return prevCount - 1;
        }

        setIsTimerOver(true);
        clearInterval(intervalId);
        return TIMER_IN_SECONDS;
      });
    },
    [setIsTimerOver],
  );

  // Start timer on mount after redirect
  useEffect(() => {
    const id = setInterval(() => {
      startTimer(id);
    }, INTERVAL_DELAY);

    return () => {
      clearInterval(id);
    };
  }, [startTimer]);

  const [resetPassword, { isLoading, isSuccess, error }] = useResetPasswordMutation();

  const isEmailSent = isSuccess || isResetPasswordEmailSentError(error);

  // Start timer on success or error server response trigger (`isEmailSent`)
  // If server responded with error - timeout not yet over, restart timer
  useEffect(() => {
    if (isEmailSent) {
      const id = setInterval(() => {
        startTimer(id);
      }, INTERVAL_DELAY);

      return () => {
        clearInterval(id);
      };
    }
  }, [startTimer, isEmailSent]);

  return (
    <Button
      fullWidth
      className={styles['button']}
      disabled={!isTimerOver || isLoading}
      onClick={() => {
        if (userEnteredEmail) {
          // To avoid flickers of disable button state (fetch data and timer),
          // reset the `isTimerOver` state here
          setIsTimerOver(false);
          resetPassword({ email: userEnteredEmail });
        }
      }}
    >
      Resend email {!isTimerOver && <span>({timer}s)</span>}
    </Button>
  );
};
