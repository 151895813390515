export const isJson = (data: unknown): data is JSON => {
  if (typeof data === 'string') {
    const reg = /[^,:{}[\]0-9.\-+Eaeflnr-u \n\r\t]/;

    return !reg.test(data.replace(/"(\\.|[^"\\])*"/g, ''));
  }

  return false;
};

export const jsonParse = <D = unknown>(jsonStr: string): D | null => {
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const jsonStringify = (data: unknown) => {
  let transformedData = '';

  try {
    if (data) {
      transformedData = JSON.stringify(data);
    }
  } catch (e) {
    console.error(e);
  }

  return transformedData;
};
