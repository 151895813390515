import { QueryParams } from '@/shared/types';
import { RoleEnum } from '@/features/user-management/types/roles';
import { jsonParse, jsonStringify } from '@/shared/utils';
import { FILTERS_TABLE_KEY } from '@/shared/constants';

export const addRoleToParams = (params: QueryParams, role: RoleEnum) => {
  const parsedFiltersParams = jsonParse(params?.[FILTERS_TABLE_KEY]);

  const roleFilter = { role };

  return {
    ...params,
    [FILTERS_TABLE_KEY]: jsonStringify(parsedFiltersParams ? { ...parsedFiltersParams, ...roleFilter } : roleFilter),
  };
};
