import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { APP_LINKS } from '@/configs';
import { CheckmarkIcon, CrossIcon } from '@/shared/icons';

import { FormWrapper } from '../form-wrapper';
import { FormTitle } from '../form-title';
import { FormDescription } from '../form-description';
import { GoBackLink } from '../go-back-link';

import styles from './form-state.module.scss';

type FromStateType = 'success' | 'failure';

type SuccessStateProps = {
  type: FromStateType;
  title: string;
  description?: ReactNode;
  mainAction?: ReactNode;
  replace?: boolean;
};

const iconsMap = {
  success: <CheckmarkIcon width="2rem" height="2rem" />,
  failure: <CrossIcon width="2rem" height="2rem" />,
};

export const FormState: FC<SuccessStateProps> = ({ type, title, description, mainAction, replace }) => {
  return (
    <FormWrapper>
      <div className={styles['container']}>
        <div className={clsx(styles[type], styles['icon-container'])}>{iconsMap[type]}</div>

        <FormTitle title={title} />
        {description && <FormDescription description={description} />}

        {mainAction}

        <GoBackLink to={APP_LINKS.LOGIN} replace={replace}>
          Back to login
        </GoBackLink>
      </div>
    </FormWrapper>
  );
};
