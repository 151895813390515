import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './table-component.module.scss';

type TableComponentProps = {
  tableClassName?: string;
  children?: ReactNode;
  isEmpty?: boolean;
};

export const TableComponent: FC<TableComponentProps> = ({ tableClassName, children, isEmpty }) => {
  return (
    <div className={clsx(styles['table-container'], isEmpty && styles['empty'])}>
      <div role="table" className={clsx(tableClassName, styles['table'])}>
        {children}
      </div>
    </div>
  );
};
