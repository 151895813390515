import * as yup from 'yup';

import { EMAIL_VALIDATION_MESSAGES, EMAIL_REG_EXP, PASSWORD_VALIDATION_MESSAGES } from '@/shared/constants';

export const loginValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .required(EMAIL_VALIDATION_MESSAGES.REQUIRED)
    .test('isValidEmail', EMAIL_VALIDATION_MESSAGES.IS_INVALID, (value) => {
      if (value) {
        return new RegExp(EMAIL_REG_EXP).test(value);
      }
      return false;
    }),
  password: yup.string().trim().required(PASSWORD_VALIDATION_MESSAGES.REQUIRED),
});
