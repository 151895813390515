import { api } from '@/setup/redux/query';

import { ResponseUniversitiesDto } from '@/features/universities/dto';

import { UNIVERSITIES_ENDPOINT } from './endpoints';

export const universitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUniversities: builder.query<ResponseUniversitiesDto, void>({
      query: () => ({
        url: UNIVERSITIES_ENDPOINT,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUniversitiesQuery } = universitiesApi;
