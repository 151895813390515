import { FC, ReactNode } from 'react';

import styles from './form-description.module.scss';

type FormDescriptionProps = {
  description: ReactNode;
};

export const FormDescription: FC<FormDescriptionProps> = ({ description }) => {
  return <h6 className={styles['description']}>{description}</h6>;
};
