import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { BaseModal, BaseModalProps } from '../base-modal';

import style from './modal-blank-wrapper.module.scss';

export type ModalBlankWrapperProps = BaseModalProps & {
  children: ReactNode;
  isOpen: boolean;
  ariaLabel?: string;
  hideModal?: () => void;
  className?: string;
  contentWrapperClassName?: string;
  role?: string;
  internalScroll?: boolean;
};

export const ModalBlankWrapper: FC<ModalBlankWrapperProps> = ({
  children,
  className,
  contentWrapperClassName,
  internalScroll,
  ...restProps
}) => {
  return (
    <BaseModal {...restProps} className={clsx(style['wrapper'], className)}>
      <div className={style['scrollWrapper']}>
        <div className={clsx(contentWrapperClassName, style['contentWrapper'])}>
          <div className={clsx(internalScroll && style['internalScroll'])}>{children}</div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalBlankWrapper;
