import { createApi, FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { RootState } from '@/setup';
import { API_BASE_URL } from '@/shared/constants';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { logout } from '@/features/auth/slices';
import { jsonStringify } from '@/shared/utils';

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const access = (getState() as RootState).auth.tokens.access;

    if (access) {
      headers.set('Authorization', `Bearer ${access}`);
    }

    return headers;
  },
});

const retryRequestHandler = (responseStatus: number, result: QueryReturnValue) => {
  if (responseStatus >= 500) return;
  retry.fail(result.error);
};

const baseQueryHandler = retry(
  async (args: FetchArgs, api, extraOptions) => {
    if ((args.method === 'POST' || args.method === 'PUT') && !args.body) {
      args.body = jsonStringify({});
    }

    const result = await baseQuery(args, api, extraOptions);

    const responseStatus = result.error?.status;

    if (typeof responseStatus !== 'number') return result;

    if (responseStatus === 401) {
      api.dispatch(logout());
    }

    retryRequestHandler(responseStatus, result);

    return result;
  },
  {
    maxRetries: 5,
  },
);

export const api = createApi({
  baseQuery: baseQueryHandler,
  endpoints: () => ({}),
});
