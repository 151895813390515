import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './chip.module.scss';

export type Variants = 'success' | 'error' | 'info';

type ChipProps = {
  children: ReactNode;
  variant?: Variants;
  className?: string;
};

export const Chip: FC<ChipProps> = ({ children, variant = 'info', className }) => {
  return <div className={clsx(className, styles['chip'], styles[variant])}>{children}</div>;
};
