import { FC, ReactNode } from 'react';

import styles from './form-wrapper.module.scss';

type FormWrapperProps = {
  children: ReactNode;
};
export const FormWrapper: FC<FormWrapperProps> = ({ children }) => {
  return <div className={styles['container']}>{children}</div>;
};
