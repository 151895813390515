import { forwardRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import { CheckmarkIcon } from '@/shared/icons';

import styles from './checkbox.module.scss';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      labelClassName,
      containerClassName,
      defaultChecked: defaultCheckedProp = false,
      name: nameProp,
      id: idProp,
      checked: checkedProp,
      ...restProps
    },
    ref,
  ) => {
    return (
      <div className={clsx(containerClassName, styles['container'])}>
        <div className={styles['checkbox-container']}>
          <input
            {...restProps}
            ref={ref}
            id={idProp || nameProp}
            name={nameProp}
            type="checkbox"
            defaultChecked={defaultCheckedProp}
            className={clsx(checkedProp && styles['checked'], styles['checkbox'])}
          />
          {checkedProp && <CheckmarkIcon className={styles['checkbox-icon']} />}
        </div>

        {label && (
          <label
            htmlFor={idProp || nameProp}
            className={clsx(labelClassName, styles['checkbox-label'], checkedProp && styles['checked'])}
          >
            {label}
          </label>
        )}
      </div>
    );
  },
);
