import * as yup from 'yup';

import { PASSWORD_VALIDATION_MESSAGES, PASSWORD_REG_EXP, PASSWORD_MAX_LENGTH } from '@/shared/constants';

export const setPasswordValidationSchema = yup.object({
  password: yup
    .string()
    .trim()
    .required(PASSWORD_VALIDATION_MESSAGES.REQUIRED)
    .max(PASSWORD_MAX_LENGTH, PASSWORD_VALIDATION_MESSAGES.max(PASSWORD_MAX_LENGTH))
    .test('isValidEmail', PASSWORD_VALIDATION_MESSAGES.IS_INVALID, (value) => {
      if (value) {
        return new RegExp(PASSWORD_REG_EXP).test(value);
      }
      return false;
    }),
});
