import { FC, ReactNode } from 'react';

import { TableColumnConfig } from '@/shared/types';

import { TableHeadRow } from './table-head-row';
import { TableRowGroup } from './table-row-group';
import { TableComponent } from './table-component';
import { TableHead } from './table-head';
import { TableFullOverlay } from './table-full-overlay';

type TableContainerProps = {
  tableClassName?: string;
  headRowClassName?: string;
  columnsConfig: TableColumnConfig[];
  children?: ReactNode;
  disableSorting?: boolean;
  shouldShowOverlay?: boolean;
  isEmpty?: boolean;
};

export const TableContainer: FC<TableContainerProps> = ({
  tableClassName,
  headRowClassName,
  columnsConfig,
  children,
  disableSorting,
  shouldShowOverlay,
  isEmpty,
}) => {
  return (
    <TableComponent isEmpty={isEmpty} tableClassName={tableClassName}>
      <TableRowGroup sticky>
        <TableHeadRow headRowClassName={headRowClassName}>
          {columnsConfig.map(({ key, title, ...restProps }) => (
            <TableHead key={key} disableSorting={isEmpty || disableSorting} {...restProps}>
              {title}
            </TableHead>
          ))}
        </TableHeadRow>
      </TableRowGroup>

      <TableRowGroup>{children}</TableRowGroup>

      {shouldShowOverlay && <TableFullOverlay />}
    </TableComponent>
  );
};
