import { FC } from 'react';

import styles from './avatar.module.scss';

export type AvatarProps = {
  avatarUrl?: string;
  size: number;
};

export const Avatar: FC<AvatarProps> = ({ avatarUrl, size }) => {
  if (!avatarUrl) return null;

  const style = { minWidth: size, width: size, maxWidth: size, minHeight: size, height: size, maxHeight: size };

  return (
    <div className={styles['avatar']} style={style}>
      <img className={styles['avatar-image']} src={avatarUrl} loading="lazy" alt="avatar" width={size} height={size} />
    </div>
  );
};
