import { FC } from 'react';

import styles from './form-title.module.scss';

type FormTitleProps = {
  title: string;
};

export const FormTitle: FC<FormTitleProps> = ({ title }) => {
  return <h1 className={styles['title']}>{title}</h1>;
};
