import { useLayoutEffect } from 'react';

type HeadConfig = {
  title: string;
};

export const useHead = (config: HeadConfig) => {
  useLayoutEffect(() => {
    document.title = config.title;
  }, [config]);
};
